<template>
  <div
    style="
      padding: 5px;
      margin-left: auto;
      margin-right: auto;
      max-width: 300px;
    "
  >
    <div align="center" style="padding: 5px; max-width: 300px">
      <b style="font-size: 1.4em">{{ store.storeName }}</b>
      <br />
      {{ store.address }}
      <div>
        <table width="100%">
          <tr>
            <td colspan="3"><hr /></td>
          </tr>
          <tr>
            <td colspan="2" valign="top">
              <b>{{ invoice.transactionNumber }}</b>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <b>{{
                dateTimeFormat(invoice.transactionDate, "DD MMM yyyy")
              }}</b>
            </td>
            <td align="right" valign="top">
              <b>{{ dateTimeFormat(invoice.transactionDate, "HH:mm") }}</b>
            </td>
          </tr>
          <tr>
            <td valign="top">Pelanggan</td>
            <td align="right" valign="top">
              {{ invoice.customer?.label || "-" }}
            </td>
          </tr>
          <tr>
            <td valign="top">Kasir</td>
            <td align="right" valign="top">
              {{ invoice.created?.createdBy || "-" }}
            </td>
          </tr>
          <tr>
            <td colspan="3"><hr /></td>
          </tr>
        </table>
      </div>
      <table width="100%">
        <tr
          v-for="(cart, i) in invoice.carts"
          v-bind:key="i"
          style="text-align: left"
        >
          <td align="left" colspan="2">
            {{ cart.productName }}<br />
            <fragment v-if="!isNullOrEmpty(cart.productVariantName)">
              <small>{{ cart.productVariantName }}</small
              ><br />
            </fragment>
            {{ cart.quantity }}x{{ numberFormat(cart.price) }}
            <div
              v-if="cart.discount > 0"
              style="float: right; margin-bottom: 4px"
            >
              <small style="text-decoration: line-through">{{
                numberFormat(cart.quantity * cart.price)
              }}</small>
              {{ numberFormat(cart.fixedPrice) }}
            </div>
            <div v-else style="float: right; margin-bottom: 4px">
              {{ numberFormat(cart.fixedPrice) }}
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="2"><hr /></td>
        </tr>
        <tr style="text-align: left">
          <td>Sub Total</td>
          <td align="right">{{ setRupiah(invoice.subTotal) }}</td>
        </tr>
        <tr v-if="store.taxPercentage > 0" style="text-align: left">
          <td>Pajak ({{ invoice.taxPercentage }}%)</td>
          <td align="right">{{ setRupiah(invoice.tax) }}</td>
        </tr>
        <tr style="text-align: left">
          <td>Diskon</td>
          <td align="right">{{ setRupiah(invoice.discount) }}</td>
        </tr>

        <tr>
          <td colspan="2"><hr /></td>
        </tr>
        <tr style="text-align: left">
          <td><b>Total Transaksi</b></td>
          <td align="right">
            <b>{{ setRupiah(invoice.grandTotal) }}</b>
          </td>
        </tr>
        <tr style="text-align: left">
          <td>
            <b>{{ invoice.paymentMethod?.label }}</b>
          </td>
          <td align="right">
            <b>{{ setRupiah(invoice.paymentAmount) }}</b>
          </td>
        </tr>
        <tr v-if="invoice.paymentChange > 0" style="text-align: left">
          <td><b>Kembalian</b></td>
          <td align="right">
            <b>{{ setRupiah(invoice.paymentChange) }}</b>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  dateTimeFormat,
  numberFormat,
  setRupiah,
  isNullOrEmpty,
} from "@/core/utils";

export default {
  props: {
    invoice: Object,
  },
  computed: {
    ...mapGetters(["store"]),
  },
  created() {
    const self = this;
    if (
      !isNullOrEmpty(self.$route.params.storeId) &&
      !isNullOrEmpty(self.$route.params.transactionId)
    )
      self.getTransactionById();
  },
  methods: {
    dateTimeFormat,
    numberFormat,
    setRupiah,
    isNullOrEmpty,
    getTransactionById() {
      const self = this;

      self.$store
        .dispatch("apis/get", {
          url: `/transaction/${self.$route.params.storeId}/${self.$route.params.transactionId}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.invoice = {
              transactionNumber: response.data.transactionNumber,
              transactionDate: response.data.created?.createdAt,
              customer: response.data.customer,
              subTotal: response.data.subTotal,
              taxPercentage: response.data.taxPercentage,
              tax: response.data.tax,
              discount: response.data.discount,
              grandTotal: response.data.grandTotal,
              amount: response.data.amount,
              paymentAmount: response.data.paymentAmount,
              paymentChange: response.data.paymentChange,
              paymentRemaining: response.data.paymentRemaining,
              outstanding: response.data.outstanding,
              paymentMethod: response.data.paymentMethod,
              carts: response.data.carts,
              created: response.data.created,
            };
          }
        })
        .finally(() => {
          window.print();
          setTimeout(() => {
            close();
          }, 500);
        });
    },
  },
};
</script>
